<template>
	<b-modal 
		id="confirmation-dialog" 
		:title="title" 
		ok-title="Proceed" 
		ref="modal" 
		@ok="handleOk"
		:no-close-on-backdrop="true"
	>
		<div class="confirmMessage">
			You have unsave changes to this page. Are you sure you want leave?
		</div>
	</b-modal>
</template>

<script>

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'confirmation-dialog',
	computed: {
		title() {
			return `Warning!`;
		},
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			let confirmation = true;
			EventBus.$emit('onCloseConfirmDialog', confirmation);
			this.$nextTick(() => {      
				this.$refs.modal.hide();
			})
		},
	},
};
</script>

<style>

</style>

