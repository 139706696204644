<template>
	<b-modal id="create-repaired-batch" :title="title" ok-title="Add" ref="modal" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">

		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<b-form-group label="Remaining:" label-cols-sm="3" label-class="font-weight-bold pt-0">
					{{ getRemaining() }}
				</b-form-group>

				<b-form-group label="To Repair:" label-for="repair">
					<b-form-input name="To Repair" type="number" v-model="selBatch.repaired" maxlength="50" v-validate="{
						required: true,
						regex: /^[0-9]*$/,

					}" />
					<span v-show="errors.has('Repair')" class="help-block">
						{{ errors.first('Repair') }}
					</span>
				</b-form-group>

				<b-form-group label="Repaired By:" label-for="Repaired By">
					<v-select name="Repaired By" class="style-chooser" label="text" :options="allUserOptions"
						:reduce="(user) => user.value" v-model="selUser" v-validate="'selectRequired'">
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a user
							</em>
						</template>
					</v-select>
					<span v-show="errors.has('Repaired By')" class="help-block">
						{{ errors.first('Repaired By') }}
					</span>
				</b-form-group>

				<b-form-group label="Date Time:" label-for="DateTime">
					<date-range-picker name="DateTime" :min-date="minDate" :max-date="maxDate" :timePicker="true" :timePicker24Hour="false" opens="center"
						:single-date-picker="true" v-model="dateRepaired" applyLabel="Apply" cancelLabel="Cancel"
						:style="{ width: '100%' }">
						<div slot="input">
							{{ formattedDate }}
						</div>
					</date-range-picker>
					<span v-show="errors.has('DateTime')" class="help-block">{{ errors.first('DateTime') }}</span>
				</b-form-group>

				<b-form-group label="Remarks:" label-for="Remarks" description>
					<b-form-textarea name="Remarks" type="text" v-model="selBatch.repairedRemarks" maxlength="200"
						v-validate="{
							required: true,
							regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
						}" :rows="3" placeholder="Remarks" />
					<span v-show="errors.has('Remarks')" class="help-block">{{
						errors.first('Remarks')
					}}</span>
				</b-form-group>

			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import moment from 'moment';
import _ from 'lodash';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

export default {
	name: 'create-repaired-batch',
	components: {
		Loading,
		DateRangePicker
	},
	data() {
		return {
			selBatch: {
				batchNo: 0,
				repaired: 0,
				good: 0,
				rejected: 0,
				dateRepaired: DateUtil.getCurrentTimestamp(),
				repairedBy: '',
				dateChecked: 0,
				checkedBy: '',
				dateEndorsed: 0,
				endorsedBy: '',
				repairedRemarks: '',
				checkedRemarks: '',
				endorsedRemarks: '',
				turnOverForm: {},
				status: config.batchStatus.IN_PRODUCTION.name,
				completed: false
			},

			repairedBatches: [],
			allUserOptions: [],

			selUser: config.userDefaultValue,

			repairCount: 0,
			remainingCount: 0,
			currCompanyId: null,
			loggedUser: this.$store.getters.loggedUser,
			allUsersObj: this.$store.getters.users,
			// Check for loader
			isLoading: false,
			isReEditBatch: false,
		};
	},
	computed: {
		title() {
			return `Create Batch ${this.selBatch.batchNo}`;
		},
		
		disableConfirmButtons() {
			return this.isLoading;
		},
		
		dateRepaired: {
			get() {
				return this.selBatch.dateRepaired;
			},

			set(value) {
				this.selBatch.dateRepaired = moment(value.startDate).valueOf();
			},
		},
		
		formattedDate() {
			return moment(this.selBatch.dateRepaired).format('MMMM D YYYY hh:mm A');
		},

		minDate() {
			const timestamp = DateUtil.getCurrentTimestamp();
			const adjustedTimestamp = DateUtil.getAdustedDateInDays(new Date(timestamp), 90);
			return new Date(adjustedTimestamp);
		},

		maxDate() {
			const timestamp = DateUtil.getCurrentTimestamp();
			return new Date(timestamp);
		}

	},
	mounted() {
		EventBus.$on('onEditRepairedBatch', async (value) => {
			this.isReEditBatch = true;
			if (!_.isEmpty(value)) {
				this.initRepairedBatchData(value);
				this.onReset();
				this.selBatch = value.selBatch;
				// Return count since this is a re-edit
				this.remainingCount = this.remainingCount + this.selBatch.repaired;
			}

			// Find selected company
			const selectedUser = this.allUserOptions.find(option => option.value.id === this.selBatch.repairedBy);
			if (selectedUser) {
				this.selUser = selectedUser.value;
			}
		});

		EventBus.$on('onCreateRepairedBatch', (value) => {
			if (!_.isEmpty(value)) {
				this.initRepairedBatchData(value);
			}
			this.onReset();
			this.isReEditBatch = false;
			this.selBatch.batchNo = this.repairedBatches.length + 1;
		});
	},
	watch: {
		'selBatch.repaired': function () {
			this.selBatch.repaired = parseInt(this.selBatch.repaired);
		}
	},
	methods: {

		initRepairedBatchData(value) {
			this.repairCount = value.repairCount;
			this.repairedBatches = value.repairedBatches;
			this.remainingCount = this.getComputedRemaining(this.repairCount, this.repairedBatches);
			this.currCompanyId = value.currCompanyId;
		},

		onReset() {
			this.selBatch = {
				batchNo: 0,
				repaired: 0,
				good: 0,
				rejected: 0,
				dateRepaired: DateUtil.getCurrentTimestamp(),
				repairedBy: '',
				dateChecked: 0,
				checkedBy: '',
				dateEndorsed: 0,
				endorsedBy: '',
				repairedRemarks: '',
				checkedRemarks: '',
				endorsedRemarks: '',
				turnOverForm: {},
				status: config.batchStatus.IN_PRODUCTION.name,
				completed: false
			}

			this.allUserOptions = DropDownItemsUtil.retrieveActiveUserItemsByCompanyId(this.allUsersObj, this.currCompanyId);
			this.selUser = config.userDefaultValue;
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			// update fields
			this.selBatch.updatedBy = this.loggedUser.id;

			try {

				let isValid = await this.$validator.validateAll();

				if (!isValid) {
					this.$toaster.warning('Please address the field/s with invalid input');
					this.isLoading = false;
					return;
				}

				this.updateRepairedBatch();

				let isValidParams = this.isValidBatch();
				if (isValidParams) {
					let newBatch = this.selBatch;
					this.$toaster.success(
						`Batch Repair number "${newBatch.batchNo}" was created successfully.`
					);
					EventBus.$emit('onCloseCreateRepairedBatch', newBatch);
					this.$refs.modal.hide();
				}

				// hide loading indicator
				this.isLoading = false;
			} catch (error) {
				this.$toaster.error(
					`Error adding a new batch repair. Please try again.`
				);

				// hide loading indicator
				this.isLoading = false;
			}
		},

		updateRepairedBatch() {
			this.selBatch.repaired = parseInt(this.selBatch.repaired);
			this.selBatch.repairedBy = this.selUser.id;
		},

		isValidBatch() {
			if (this.selBatch.repaired === 0) {
				this.$toaster.warning(
					`Repair count has zero value.`
				);
				return false;
			}

			let remainingCount = this.getRemaining();
			if (remainingCount < 0) {
				this.$toaster.warning(
					`Repair count is greater than the remaining asset count.`
				);
				return false;
			}

			return true;
		},

		getRemaining() {
			return this.remainingCount - this.selBatch.repaired;
		},

		getComputedRemaining(repairCount, batches) {
			let remaining = repairCount;
			if (!_.isEmpty(batches)) {
				let total = 0;
				for (const batch of batches) {
					if (batch) {
						if (batch.status !== config.batchStatus.CANCELLED.name) {
							total += (batch.repaired - batch.rejected);
						}
					}
				}
				remaining -= total;
			}
			return remaining;
		},
	},
	beforeDestroy() {
		EventBus.$off('onCreateRepairedBatch');
		EventBus.$off('onEditRepairedBatch');
		EventBus.$off('onCloseCreateRepairedBatch');
	},
};
</script>

<style scoped></style>

