<template>
	<div class="animated fadeIn">
		<b-card :title="'Repair Maintenance (' + form.maintenanceId + ')'"
			sub-title="Handles the maintenance batches of asset repairs">
			<br />
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
			<b-form @submit.stop.prevent="handleSubmit" novalidate>
				<div role="tablist">
					<!-- PRIMARY DETAILS -->
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion1 variant="primary"
								class="text-sm-left accordion-title">
								PRIMARY DETAILS
							</b-btn>
						</b-card-header>
						<b-collapse v-model="showFirstPane" id="accordion1" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-12">
									<b-col class="mt-4" lg="6" md="6" sm="12">
										<b-form-group label="Area" label-cols-sm="4" label-class="font-weight-bold pt-0"
											label-align-sm="right">
											{{ form.company + ' - ' + form.storageLocation }}
										</b-form-group>
										<b-form-group label="Asset Type" label-cols-sm="4"
											label-class="font-weight-bold pt-0" label-align-sm="right">
											{{ form.assetType }}
										</b-form-group>
										<b-form-group label="Description" label-cols-sm="4"
											label-class="font-weight-bold pt-0" label-align-sm="right">
											{{ form.description ? form.description : '-' }}
										</b-form-group>
									</b-col>

									<b-col lg="6" md="6" sm="12" v-show="isGraphVisible()">
										<b-form-group>
											<div class="chart-wrapper">
												<canvas id="classification-chart"></canvas>
											</div>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- REPAIRED BATCHES -->
					<b-card no-body class="mb-1" v-show="form.repairCount > 0">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion4 variant="primary"
								class="text-sm-left accordion-title">
								REPAIRED BATCHES
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion4" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="mb-2">
									<b-col lg="4" md="4" sm="12" align="center">
										<b-form-group label="Repair Status" label-class="font-weight-bold pt-0">
											<span class="repair-status">{{ totalRepaired }} / {{ form.repairCount }}</span>
										</b-form-group>
										<span class="remaining">
											Remaining: {{ totalRemaining }}
										</span>
									</b-col>
									<b-col class="mb-2 mt-2" lg="6" md="6" sm="12">
										<b-form-group label="Repaired" label-class="font-weight-bold pt-0" label-cols-md="4"
											label-align-sm="left" label-align-md="right">
											<b-progress :max="totalAssetCount" height="1rem" variant="success">
												<b-progress-bar :value="totalRepaired">
													<span>
														<strong>{{ totalRepaired }} /
															{{ totalAssetCount }}</strong>
													</span>
												</b-progress-bar>
											</b-progress>
										</b-form-group>
										<b-form-group label="Checked" label-cols-md="4" label-class="font-weight-bold pt-0"
											label-align-sm="left" label-align-md="right">
											<b-progress :max="totalAssetCount" height="1rem" variant="success">
												<b-progress-bar :value="totalChecked">
													<span>
														<strong>{{ totalChecked }} /
															{{ totalAssetCount }}</strong>
													</span>
												</b-progress-bar>
											</b-progress>
										</b-form-group>
										<b-form-group label="Endorsed" label-cols-md="4" label-class="font-weight-bold pt-0"
											label-align-sm="left" label-align-md="right">
											<b-progress :max="totalAssetCount" height="1rem" variant="success">
												<b-progress-bar :value="totalEndorsed">
													<span>
														<strong>{{ totalEndorsed }} /
															{{ totalAssetCount }}</strong>
													</span>
												</b-progress-bar>
											</b-progress>
										</b-form-group>
									</b-col>
								</b-row>

								<b-row class="mb-3 mt-2">
									<b-col class="ml-3" sm="5">
										<b-button v-if="totalRemaining > 0" v-b-tooltip.hover.top="'Create Batch to repair'"
											variant="dark" @click.stop="createRepairedBatch()" class="mr-1 mt-1">
											<em class="fa fa-plus"></em> Create Batch
										</b-button>
									</b-col>
								</b-row>

								<b-row class="mb-2 ml-3 mr-3">
									<b-table show-empty striped hover :items="items" :fields="fields"
										:current-page="currentPage" :per-page="perPage" :filter="filter"
										:sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
										responsive>
										<template v-slot:cell(actions)="row">
											<RepairedBatchRowActions :row="row" :selMaintenance="selMaintenance"
												:isSuperAdmin="isSuperAdmin" :isViewer="isViewer"
												parentComponent="edit-maintenance" />
										</template>
										<template v-slot:cell(status)="row">
											{{ getNextStatus(row.item.status) }}
										</template>
									</b-table>
								</b-row>
								<b-row>
									<b-col md="8" sm="12" class="my-1">
										<span class="totalDisplay">Total: {{ totalRows }}</span>
									</b-col>
									<b-col md="4" sm="12" class="my-1">
										<b-pagination align="right" :total-rows="totalRows" :per-page="perPage"
											v-model="currentPage" class="my-0" size="sm" />
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-form>
			<b-row>
				<b-col md="12" sm="1" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToMaintenanceMain">
						Cancel
					</b-button>
					&nbsp;&nbsp;
					<b-button variant="primary" @click="handleOk" :disabled="isLoading">
						Save
					</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
		</b-card>

		<!-- Modals here -->
		<CreateRepairedBatch />
		<CheckRepairedBatch />
		<EndorseRepairedBatch />
		<ImageViewDialog />
		<ConfirmationDialog />
		<AlertDialog />
	</div>
</template>

<script>
// Components
import CreateRepairedBatch from './CreateRepairedBatch.vue';
import CheckRepairedBatch from './CheckRepairedBatch.vue';
import EndorseRepairedBatch from './EndorseRepairedBatch.vue';
import RepairedBatchRowActions from './RepairedBatchRowActions.vue';
import ImageViewDialog from '../common/ImageViewDialog.vue';
import ConfirmationDialog from '../common/ConfirmationDialog.vue';
import AlertDialog from '../common/AlertDialog.vue';

// Util
import { DateUtil } from '@/utils/dateutil';
import { FileUtil } from '@/utils/fileUtil';
import { MaintenanceUtil } from '@/utils/maintenanceUtil';
import { BatchUtil } from '@/utils/batchUtil';

// API
import maintenanceApi from '@/api/maintenanceApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { storage } from '@/config/firebase';
import _ from 'lodash';
import Chart from 'chart.js';

export default {
	name: 'edit-maintenance',
	components: {
		CreateRepairedBatch,
		CheckRepairedBatch,
		EndorseRepairedBatch,
		RepairedBatchRowActions,
		ImageViewDialog,
		ConfirmationDialog,
		AlertDialog,
		Loading,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'batchNo',
					label: 'Batch No.',
				},
				{
					key: '_repaired',
					label: 'Repaired',
					sortable: true,
				},
				{
					key: '_checked',
					label: 'Checked',
					sortable: true,
				},
				{
					key: '_endorsed',
					label: 'Endorsed',
					sortable: true,
				},
				{
					key: '_remarks',
				},
				{
					key: 'status',
				},
				'actions',
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			totalRemaining: 0,
			totalRepaired: 0,
			totalChecked: 0,
			totalEndorsed: 0,
			totalAssetCount: 0,

			form: {
				id: '',
				maintenanceId: '',
				company: '',
				companyId: '',
				storageLocation: '',
				storageLocationId: '',
				assetType: '',
				assetTypeId: '',
				repairCount: 0,
				disposedCount: 0,
				repairedBatches: [],
				disposals: [],
				disposalRemarks: '',
				clientApprovalForm: null,
				capexForm: null,
				approvalFormRemarks: '',
				status: '',
				description: '',
				dateCreated: '',
				createdBy: '',
				dateUpdate: '',
				dateUpdatedBy: '',
			},

			disposePhotoName: [],

			hasChanges: false,
			showFirstPane: true,
			selMaintenance: {},
			toUpoadTGForms: [],

			// Params from Maintenance
			params: {},
			allUsersObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,

			// Check for loader
			isLoading: false,
			nexPage: null,
		};
	},
	computed: {
		total() {
			return this.form.disposedCount + this.form.repairCount;
		}
	},

	beforeMount() {
		for (let i = 0; i < 3; i++) {
			this.form.disposals[i] = {
				name: '',
				url: '',
				latitude: 0,
				longitude: 0
			}
		}
	},

	mounted() {
		// Don't initiate data retrieval when the account is not authenticated
		if (!this.$store.getters.isAuthenticated) {
			return;
		}

		// show loading indicator
		this.isLoading = true;

		setTimeout(() => {
			// init parameters
			this.params = this.$store.getters.maintenanceParams;

			// Objects
			this.allUsersObj = this.params.allUsersObj;
			this.selMaintenance = this.$store.getters.currMaintenance;

			// reset all area fields
			this.onReset();

			const ctx = document.getElementById('classification-chart');
			new Chart(ctx, {
				type: 'pie',
				data: {
					labels: [
						'For Disposal (' + this.form.disposedCount + ')',
						'For Repair (' + this.form.repairCount + ')',
					],
					datasets: [
						{
							data: [this.form.disposedCount, this.form.repairCount],
							borderWidth: 1,
							backgroundColor: ['rgb(241, 143, 1)', 'rgb(18, 43, 145)'],
						},
					],
				},
				options: {
					scales: {
						y: {
							beginAtZero: true
						}
					},
					legend: {
						position: 'top',
					},
					title: {
						display: true,
						text: 'Classification (' + this.total + ')'
					}
				}
			});

			this.processBatches(this.selMaintenance.repairedBatches);

			this.totalRepaired = MaintenanceUtil.getRepairedAssetCount(this.selMaintenance.repairedBatches);
			this.totalRemaining = this.selMaintenance.repairCount - this.totalRepaired;

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseCreateRepairedBatch', (repairedBatchObj) => {
			this.updateTable(repairedBatchObj);
		});

		EventBus.$on('onCloseCheckRepairedBatch', (repairedBatchObj) => {
			this.updateTable(repairedBatchObj);
		});

		EventBus.$on('onCloseEndorseRepairedBatch', (repairedBatchObj) => {
			this.updateTable(repairedBatchObj);
			this.validatePendingBatches();
		});

		EventBus.$on('onCloseConfirmDialog', (confirmation) => {
			this.nexPage(confirmation);
		});
	},

	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			this.form = this.processMaintenanceForm();
			await this.handleSubmit();

			// hide loading indicator
			this.isLoading = false;
		},

		async handleSubmit() {
			let maintenanceId = this.form.maintenanceId;
			let currUserId = this.loggedUser.id;

			try {
				// run the add maintenance api
				let result = await maintenanceApi.updateMaintenance(this.form, currUserId);
				let data = result.data;

				if (data.isSuccess) {
					// upload document forms
					if (this.toUpoadTGForms && this.toUpoadTGForms.length > 0) {
						await this.uploadTurnOverFormImages(data.maintenance);
					}

					this.$toaster.success(
						`Repair of Maintenance "${maintenanceId}" was updated successfully.`
					);

					EventBus.$emit('onCloseEditMaintenance', data.maintenance);

					this.hasChanges = false;

					// reset the state and revert to maintenance page
					this.returnToMaintenanceMain();
				} else {
					this.$toaster.warning(data.message);
				}

			} catch (error) {
				this.$toaster.error(
					`Error updating repair details for maintenance ${maintenanceId}. Please try again.`
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		async uploadTurnOverFormImages(maintenance) {
			let repairedBatches = maintenance.repairedBatches;
			for (const toUploadForm of this.toUpoadTGForms) {
				let name = `TF_${maintenance.maintenanceId}_${toUploadForm.id}.jpg`;
				const result = await this.firebaseUploadImage(toUploadForm.file, name);
				if (result.name && result.url) {
					let isFound = false;
					for (let i = 0; i < repairedBatches.length && !isFound; i++) {
						let batch = repairedBatches[i];
						if (batch.batchNo === toUploadForm.id) {
							batch.turnOverForm.name = name;
							batch.turnOverForm.url = result.url;
							repairedBatches[i] = batch;
							isFound = true;
						}
					}

					maintenance.repairedBatches = repairedBatches;
					await maintenanceApi.updateMaintenance(
						maintenance,
						this.loggedUser.id
					);
				}
			}
		},

		async firebaseUploadImage(image, name) {
			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${image.fbStoragePath}/${name}`);
				let task = storageRef.put(image.file);
				task.on(
					'state_changed',
					(snapshot) => { },
					(error) => { },
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: name,
								url: downloadURL,
							});
						});
					}
				);
			});
		},

		processMaintenanceForm() {
			// Update Maintenance Fields
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			let repairedBatches = this.form.repairedBatches;
			for (let i = 0; i < repairedBatches.length; i++) {
				let image = repairedBatches[i]._turnOverForm;
				if (image && image._isNew) {
					this.toUpoadTGForms.push({
						id: repairedBatches[i].batchNo,
						file: image,
					});
				}

				this.form.repairedBatches[i] = BatchUtil.cleanupFields(
					repairedBatches[i]
				);
			}

			return this.form;
		},

		returnToMaintenanceMain() {
			this.params.fromAddMaintenance = true;

			this.$store.dispatch('setMaintenanceParams', this.params);
			this.$store.dispatch('setCurrentMaintenance', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/maintenance' });
			} else {
				this.$router.push({ path: '/admin/admin-maintenance' });
			}
		},

		validatePendingBatches() {
			let repairedBatches = this.selMaintenance.repairedBatches;
			let totalCompleted =
				MaintenanceUtil.getEndorsedAssetCount(repairedBatches);
			let hasPending = false;

			if (totalCompleted == this.selMaintenance.repairCount) {
				for (let batch of repairedBatches) {
					if (!batch.completed) {
						batch.status = config.batchStatus.CANCELLED.name;
						hasPending = true;
					}
				}
			}

			if (hasPending) {
				EventBus.$emit('onShowAlertDialog', {
					message:
						'All assets are repaired and existing uncompleted batches are cancelled.',
				});
				this.$bvModal.show('alert-dialog');
			}

			this.selMaintenance.repairedBatches = repairedBatches;
			this.onReset();
			this.processBatches(this.form.repairedBatches);
		},

		async updateTable(repairedBatchObj) {
			this.hasChanges = true;

			if (_.isEmpty(repairedBatchObj)) {
				return;
			}

			let repairedBatches = this.selMaintenance.repairedBatches;

			let isFound = false;
			for (let i = 0; i < repairedBatches.length; i++) {
				let batch = repairedBatches[i];
				if (batch.batchNo === repairedBatchObj.batchNo) {
					repairedBatches[i] = repairedBatchObj;
					isFound = true;
				}
			}
			if (!isFound) {
				repairedBatches.push(repairedBatchObj);
			}

			this.selMaintenance.repairedBatches = repairedBatches;

			this.onReset();
			this.processBatches(this.form.repairedBatches);
		},

		processBatches(batches) {
			if (_.isEmpty(batches)) return;

			this.totalRepaired = MaintenanceUtil.getRepairedAssetCount(batches);
			this.totalChecked = MaintenanceUtil.getCheckedAssetCount(batches);
			this.totalEndorsed = MaintenanceUtil.getEndorsedAssetCount(batches);
			this.totalRemaining = this.selMaintenance.repairCount - this.totalRepaired;
			this.totalAssetCount = this.selMaintenance.repairCount;

			this.items = Object.values(batches);
			this.items = _.sortBy(this.items, ['batchNo']);

			// status
			this.items.forEach((item) => {
				// Repaired
				if (item.repairedBy) {
					item['_repaired'] = item.repaired - item.rejected;
					item['_remarks'] = item.repairedRemarks;
				} else {
					item['_repaired'] = 0;
				}

				// Checked
				if (item.checkedBy) {
					item['_checked'] = item.good;
					item['_remarks'] = item.checkedRemarks;
				} else {
					item['_checked'] = 0;
				}

				// Endorsed
				if (item.endorsedBy) {
					item['_endorsed'] = item.good;
					item['_remarks'] = item.endorsedRemarks;
				} else {
					item['_endorsed'] = 0;
				}
			});
			this.totalRows = this.items.length;
		},

		onReset() {
			/* Reset our form values */
			let maintenance = this.selMaintenance;

			// primary details
			this.form.id = maintenance.id;
			this.form.maintenanceId = maintenance.maintenanceId;
			this.form.company = maintenance.company;
			this.form.companyId = maintenance.companyId;
			this.form.storageLocation = maintenance.storageLocation;
			this.form.storageLocationId = maintenance.storageLocationId;
			this.form.assetType = maintenance.assetType;
			this.form.assetTypeId = maintenance.assetTypeId;
			this.form.repairCount = maintenance.repairCount;
			this.form.disposedCount = maintenance.disposedCount;
			this.form.repairedBatches = maintenance.repairedBatches;
			this.form.status = maintenance.status;
			this.form.description = maintenance.description;
			this.form.disposals = maintenance.disposals;
			this.form.disposalRemarks = maintenance.disposalRemarks;
			this.form.clientApprovalForm = maintenance.clientApprovalForm;
			this.form.capexForm = maintenance.capexForm;
			this.form.approvalFormRemarks = maintenance.approvalFormRemarks;
			this.form.dateCreated = maintenance.dateCreated;
			this.form.createdBy = maintenance.createdBy;

			this.disposePhotoName = FileUtil.getUrlFileName(maintenance.disposals);

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},

		showImage(url) {
			let fileName = FileUtil.getUrlFileName(url);
			EventBus.$emit('onSelectImageView', {
				url: url,
				name: fileName,
			});
			this.$bvModal.show('image-view-dialog');
		},

		hasMedia(media) {
			return media && media.url && !_.isEmpty(media.url);
		},

		isGraphVisible() {
			return this.form.disposedCount && this.form.repairCount;
		},

		createRepairedBatch() {
			if (this.totalRemaining > 0) {
				EventBus.$emit('onCreateRepairedBatch', {
					repairCount: this.form.repairCount,
					repairedBatches: this.form.repairedBatches,
					currCompanyId: this.form.companyId
				});
				this.$bvModal.show('create-repaired-batch');
			} else {
				EventBus.$emit('onShowAlertDialog', {
					message: 'No more remaining assets to repair.',
				});
				this.$bvModal.show('alert-dialog');
			}
		},

		getNextStatus(status) {
			const statusMapping = {
				[config.batchStatus.IN_PRODUCTION.name]: config.batchStatus.IN_PRODUCTION.nextStatus,
				[config.batchStatus.IN_QC.name]: config.batchStatus.IN_QC.nextStatus,
				[config.batchStatus.IN_WAREHOUSE.name]: config.batchStatus.IN_WAREHOUSE.nextStatus,
			};

			return statusMapping[status] || "";
		}

	},
	beforeRouteLeave(to, from, next) {
		if (this.hasChanges) {
			this.nexPage = next;
			this.$bvModal.show('confirmation-dialog');
		} else {
			next(true);
		}
	},
};
</script>

<style scoped>
.accordion-title {
	color: white !important;
}

.repair-status {
	font-size: 3em;
	/* font-weight: bold; */
}

.remaining {
	color: #f18f01 !important;
	font-weight: bold;
	font-size: 14px;
}

.chart-wrapper {
	display: inline-block;
	position: relative;
	width: 70%;
}

.form-layout {
	margin-top: -5px;
	margin-bottom: -5px;
	font-style: italic;
	font-size: 12px;
}

.remarks {
	font-style: italic;
	color: #122c91 !important;
}
</style>
