<template>
	<b-modal id="endorse-repaired-batch" :title="title" ok-title="Add" ref="modal" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">

		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>

				<b-form-group label="Date Created:" label-cols-sm="4" label-class="font-weight-bold pt-0">
					{{ getFormattedDateWithTime(form.dateRepaired) }}
				</b-form-group>

				<b-form-group label="Repaired By:" label-cols-sm="4" label-class="font-weight-bold pt-0">
					{{ form.repairedBy }}
				</b-form-group>

				<b-form-group label="Remarks:" label-cols-sm="4" label-class="font-weight-bold pt-0">
					{{ form.repairedRemarks }}
				</b-form-group>

				<b-form-group label="Asset Type:" label-cols-sm="4" label-class="font-weight-bold pt-0">
					{{ currAssetType }}
				</b-form-group>

				<b-form-group label="Total:" label-cols-sm="4" label-class="font-weight-bold pt-0">
					{{ form.good }}
				</b-form-group>

				<b-form-group label="Endorsed By:" label-for="Endorsed By">
					<v-select name="Endorsed By" class="style-chooser" label="text" :options="allUserOptions"
						:reduce="(user) => user.value" v-model="selUser" v-validate="'selectRequired'">
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a user
							</em>
						</template>
					</v-select>
					<span v-show="errors.has('Endorsed By')" class="help-block">
						{{ errors.first('Endorsed By') }}
					</span>
				</b-form-group>

				<b-form-group label="Remarks:" label-for="Remarks" description>
					<b-form-textarea name="Remarks" type="text" v-model="form.endorsedRemarks" maxlength="200" v-validate="{
						required: true,
						regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
					}" :rows="3" placeholder="Remarks" />
					<span v-show="errors.has('Remarks')" class="help-block">
						{{ errors.first('Remarks') }}
					</span>
				</b-form-group>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import _ from 'lodash';

export default {
	name: 'endorse-repaired-batch',
	components: {
		Loading,
	},
	data() {
		return {
			form: {
				batchNo: 0,
				repaired: 0,
				good: 0,
				rejected: 0,
				dateRepaired: 0,
				repairedBy: '',
				dateChecked: 0,
				checkedBy: '',
				dateEndorsed: 0,
				endorsedBy: '',
				repairedRemarks: '',
				checkedRemarks: '',
				endorsedRemarks: '',
				turnOverForm: {},
				status: '',
				completed: false,

				// File object
				_turnOverForm: {}
			},

			currAssetType: '',
			selBatch: {},
			allUserOptions: [],
			selUser: config.userDefaultValue,

			loggedUser: this.$store.getters.loggedUser,
			allUsersObj: this.$store.getters.users,
			currCompanyId: null,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		title() {
			return 'Endorse Repaired Batch';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {
		EventBus.$on('onEndorseRepairedBatch', (value) => {

			this.isLoading = true;

			if (!_.isEmpty(value)) {
				this.selBatch = value.selBatch;
				this.currAssetType = value.assetType;
				this.currCompanyId = value.currCompanyId;
			}

			this.onReset();

			this.isLoading = false;
		});
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			// update fields
			this.selBatch.updatedBy = this.loggedUser.id;

			try {

				let isValid = await this.$validator.validateAll();

				if (!isValid) {
					this.$toaster.warning('Please address the field/s with invalid input');
					this.isLoading = false;
					return;
				}

				this.updateRepairedBatch();

				let newBatch = this.form;
				this.$toaster.success(
					`Batch Repair number "${newBatch.batchNo}" was endorse successfully.`
				);
				EventBus.$emit('onCloseEndorseRepairedBatch', newBatch);
				this.$refs.modal.hide();

				// hide loading indicator
				this.isLoading = false;
			} catch (error) {
				this.$toaster.error(
					`Error saving batch repair. Please try again.`
				);

				// hide loading indicator
				this.isLoading = false;
			}
		},

		updateRepairedBatch() {
			this.form.endorsedBy = this.selUser.id;
			this.form.dateEndorsed = DateUtil.getCurrentTimestamp();
			this.form.status = config.batchStatus.IN_WAREHOUSE.name;
			this.form.completed = true;
		},

		onReset() {

			this.form = {
				batchNo: this.selBatch.batchNo,
				repaired: this.selBatch.repaired,
				good: this.selBatch.good,
				rejected: this.selBatch.rejected,
				dateRepaired: this.selBatch.dateRepaired,
				repairedBy: this.selBatch.repairedBy,
				dateChecked: this.selBatch.dateChecked,
				checkedBy: this.selBatch.checkedBy,
				dateEndorsed: this.selBatch.dateEndorsed,
				endorsedBy: this.selBatch.endorsedBy,
				repairedRemarks: this.selBatch.repairedRemarks,
				checkedRemarks: this.selBatch.checkedRemarks,
				endorsedRemarks: this.selBatch.endorsedRemarks,
				turnOverForm: this.selBatch.turnOverForm,
				status: this.selBatch.status,
				completed: this.selBatch.completed,

				// File object
				_turnOverForm: this.selBatch._turnOverForm
			};

			if (!this.form._turnOverForm) {
				this.form._turnOverForm = {
					url: '',
					file: {},
					fbStoragePath: '',
					_isNew: false,
				};
			}

			this.allUserOptions = DropDownItemsUtil.retrieveActiveUserItemsByCompanyId(this.allUsersObj, this.currCompanyId);
			this.selUser = config.userDefaultValue;
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		}
	},
	beforeDestroy() {
		EventBus.$off('onEndorseRepairedBatch');
		EventBus.$off('onCloseEndorseRepairedBatch');
	},
};
</script>

<style scoped></style>

