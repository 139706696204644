<template>
	<b-modal id="check-repaired-batch" :title="title" ok-title="Save" ref="modal" @ok="handleOk"
		:cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons" :no-close-on-backdrop="true">

		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>

				<b-form-group label="Total:" label-cols-sm="2" label-class="font-weight-bold pt-0">
					{{ form.repaired }}
				</b-form-group>

				<b-form-group label="Good Assets:" label-for="Good Assets">
					<b-form-input name="Good Asset" type="number" v-model="goodAsset" maxlength="50" v-validate="{
						required: true,
						regex: /^[0-9]*$/,

					}" />
					<span v-show="errors.has('Good Assets')" class="help-block">
						{{ errors.first('Good Assets') }}
					</span>
				</b-form-group>

				<b-form-group label="Rejected Assets:" label-for="Rejected Assets">
					<b-form-input name="Rejected Asset" type="number" v-model="rejectedAsset" maxlength="50" v-validate="{
						required: true,
						regex: /^[0-9]*$/,

					}" />
					<span v-show="errors.has('Rejected Assets')" class="help-block">
						{{ errors.first('Rejected Assets') }}
					</span>
				</b-form-group>

				<b-form-group label="Checked By:" label-for="Checked By">
					<v-select name="Checked By" class="style-chooser" label="text" :options="allUserOptions"
						:reduce="(user) => user.value" v-model="selUser" v-validate="'selectRequired'">
						<template v-slot:no-options="{ search, searching }">
							<template v-if="searching">
								No results found for
								<em>
									<strong>{{ search }}</strong>
								</em>
							</template>
							<em :style="{ opacity: 0.5 }" v-else>
								Start typing to search for a user
							</em>
						</template>
					</v-select>
					<span v-show="errors.has('Checked By')" class="help-block">
						{{ errors.first('Checked By') }}
					</span>
				</b-form-group>

				<b-form-group label="Remarks:" label-for="Remarks" description>
					<b-form-textarea name="Remarks" type="text" v-model="form.checkedRemarks" maxlength="200" v-validate="{
						required: true,
						regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
					}" :rows="3" placeholder="Remarks" />
					<span v-show="errors.has('Remarks')" class="help-block">
						{{ errors.first('Remarks') }}
					</span>
				</b-form-group>

				<b-form-group label="Turn Over Form:" label-for="client-form"
					description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
					<b-form-file id="turnover-form" placeholder="Choose image" ref="turnover-form-file-input"
						accept="image/png" @change="onSelectTurnOverForm($event)">
						<template slot="file-name" slot-scope="{ names }">
							<b-badge variant="dark">{{ names[0] }}</b-badge>
							<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{ names.length - 1 }} More
								files</b-badge>
						</template>
					</b-form-file>
				</b-form-group>

				<!-- Image Preview -->
				<div v-if="form._turnOverForm.url">
					<b-card overlay :img-src="form._turnOverForm.url" img-alt="Card Image" text-variant="white"
						class="mb-0">
						<b-card-text class="text-right">
							<b-button variant="danger" size="sm" @click="onRemoveTurnOverForm()">
								<em class="fa fa-trash"></em>
							</b-button>
						</b-card-text>
					</b-card>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { FileUtil } from '@/utils/fileUtil';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import _ from 'lodash';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

export default {
	name: 'check-repaired-batch',
	components: {
		Loading,
	},
	data() {
		return {
			form: {
				batchNo: 0,
				repaired: 0,
				good: 0,
				rejected: 0,
				dateRepaired: 0,
				repairedBy: '',
				dateChecked: 0,
				checkedBy: '',
				dateEndorsed: 0,
				endorsedBy: '',
				repairedRemarks: '',
				checkedRemarks: '',
				endorsedRemarks: '',
				turnOverForm: {
					name: '',
					url: '',
					longitude: 0,
					latitude: 0,
				},
				status: '',
				completed: false,

				// File object
				_turnOverForm: {}
			},
			goodAsset: 0,
			rejectedAsset: 0,
			selBatch: {},
			currCompanyId: null,
			allUserOptions: [],
			selUser: config.userDefaultValue,

			loggedUser: this.$store.getters.loggedUser,
			allUsersObj: this.$store.getters.users,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		goodAsset: function () {

			if (this.isLoading)
				return;

			this.goodAsset = parseInt(this.goodAsset);
			if (this.goodAsset <= this.form.repaired) {
				this.rejectedAsset = this.form.repaired - this.goodAsset;
			} else {
				this.rejectedAsset = 0;
			}
		},

		rejectedAsset: function () {
			this.rejectedAsset = parseInt(this.rejectedAsset);
			if (this.rejectedAsset <= this.form.repaired) {
				this.goodAsset = this.form.repaired - this.rejectedAsset;
			} else {
				this.goodAsset = 0;
			}
		}
	},
	computed: {
		title() {
			return 'Check Repaired Batch';
		},
		disableConfirmButtons() {
			return this.isLoading;
		},
	},
	mounted() {

		this.getCurrentLocation();

		EventBus.$on('onCheckRepairedBatch', (value) => {

			this.isLoading = true;

			if (!_.isEmpty(value)) {
				this.selBatch = value.selBatch;
				this.currCompanyId = value.currCompanyId;
			}

			this.onReset();

			setTimeout(() => {
				this.isLoading = false;
			}, config.timeout);
		});
	},
	methods: {
		async getCurrentLocation() {
			try {
				this.currLocation = await this.$getCurrentLocation();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}
		},
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			// show loading indicator
			this.isLoading = true;

			// update fields
			this.selBatch.updatedBy = this.loggedUser.id;

			try {

				let isValid = await this.$validator.validateAll();

				if (!isValid) {
					this.$toaster.warning('Please address the field/s with invalid input');
					this.isLoading = false;
					return;
				}

				this.updateRepairedBatch();

				let isValidParams = this.isValidBatch();
				if (isValidParams) {
					let newBatch = this.form;
					this.$toaster.success(
						`Batch Repair number "${newBatch.batchNo}" was checked successfully.`
					);
					EventBus.$emit('onCloseCheckRepairedBatch', newBatch);
					this.$refs.modal.hide();
				}

				// hide loading indicator
				this.isLoading = false;
			} catch (error) {
				this.$toaster.error(
					`Error saving batch repair. Please try again.`
				);

				// hide loading indicator
				this.isLoading = false;
			}
		},

		updateRepairedBatch() {
			this.form.good = parseInt(this.goodAsset);
			this.form.rejected = parseInt(this.rejectedAsset)
			this.form.checkedBy = this.selUser.id;
			this.form.turnOverForm.name = `TF_${this.selBatch.batchNo}`;
			this.form.turnOverForm.longitude = this.currLocation ? this.currLocation.lng : 0;
			this.form.turnOverForm.latitude = this.currLocation ? this.currLocation.lat : 0;
			this.form.turnOverForm.url = this.form._turnOverForm.url;
			this.form.dateChecked = DateUtil.getCurrentTimestamp();
			this.form.status = config.batchStatus.IN_QC.name;
		},

		isValidBatch() {
			let totalChecked = this.form.good + this.form.rejected;
			if (totalChecked > this.form.repaired) {
				this.$toaster.warning(
					`Total of Good and Rejected assets is more than the total repaired count.`
				);
				return false;
			}

			if (totalChecked < this.form.repaired) {
				this.$toaster.warning(
					`Total of Good and Rejected assets is less than the total repaired count.`
				);
				return false;
			}

			if (!this.form._turnOverForm ||
				!this.form._turnOverForm.url ||
				this.form._turnOverForm.url.length === 0) {
				this.$toaster.warning(
					`Turn Over Form is required.`
				);
				return false;
			}

			return true;
		},

		onRemoveTurnOverForm(index) {
			this.form._turnOverForm = '';
			this.$refs['turnover-form-file-input'].reset();
		},

		onSelectTurnOverForm(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid company logo in PNG or JPEG format.'
				);
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"Turn over form photo's width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.form._turnOverForm = {
						url: url,
						file: file,
						fbStoragePath: 'images/turnOverForms',
						_isNew: true,
					};
				}
			};
			image.src = url;
		},

		onReset() {

			this.form = {
				batchNo: this.selBatch.batchNo,
				repaired: this.selBatch.repaired,
				good: this.selBatch.good,
				rejected: this.selBatch.rejected,
				dateRepaired: this.selBatch.dateRepaired,
				repairedBy: this.selBatch.repairedBy,
				dateChecked: this.selBatch.dateChecked,
				checkedBy: this.selBatch.checkedBy,
				dateEndorsed: this.selBatch.dateEndorsed,
				endorsedBy: this.selBatch.endorsedBy,
				repairedRemarks: this.selBatch.repairedRemarks,
				checkedRemarks: this.selBatch.checkedRemarks,
				endorsedRemarks: this.selBatch.endorsedRemarks,
				turnOverForm: this.selBatch.turnOverForm,
				status: this.selBatch.status,
				completed: this.selBatch.completed,

				// File object
				_turnOverForm: this.selBatch._turnOverForm
			};

			if (this.form.turnOverForm) {
				this.form._turnOverForm = this.selBatch.turnOverForm;
			} else if (!this.form._turnOverForm) {
				this.form._turnOverForm = {
					url: '',
					file: {},
					fbStoragePath: '',
					_isNew: false,
				};
			}

			this.goodAsset = this.selBatch.good;
			this.rejectedAsset = this.selBatch.rejected;

			this.allUserOptions = DropDownItemsUtil.retrieveActiveUserItemsByCompanyId(this.allUsersObj, this.currCompanyId);
			this.selUser = config.userDefaultValue;

			if (this.form.checkedBy && this.form.checkedBy.length > 0) {
				for (const userOpt of this.allUserOptions) {
					if (userOpt.value.id == this.form.checkedBy) {
						this.selUser = userOpt.value;
						break;
					}
				}
			}
		},
	},
	beforeDestroy() {
		EventBus.$off('onCheckRepairedBatch');
		EventBus.$off('onCloseCheckRepairedBatch');
	},
};
</script>

<style scoped></style>

