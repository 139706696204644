export const BatchUtil = {

	cleanupFields(batch) {
		let cleanBatch = Object.assign({}, batch);

		delete cleanBatch['_repaired'];
		delete cleanBatch['_checked'];
		delete cleanBatch['_endorsed'];
		delete cleanBatch['_remarks'];
		delete cleanBatch['_turnOverForm'];

		return cleanBatch;
	},
}
