<template>
	<b-modal 
		id="alert-dialog" 
		:title="title" 
		ok-title="OK" 
		ok-only
		ref="modal" 
		@ok="handleOk"
		:no-close-on-backdrop="true"
	>
		<div class="confirmMessage">
			{{ message }}
		</div>
	</b-modal>
</template>

<script>

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'confirmation-dialog',
	computed: {
		title() {
			return `Information!`;
		},
	},
	data() {
		return {
			message: ''
		}
	},
	mounted() {
		EventBus.$on('onShowAlertDialog', (params) => {
			this.message = params.message;
		});
	},
	beforeDestroy() {
		EventBus.$off('onShowAlertDialog');
	},
	methods: {
		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			this.$nextTick(() => {      
				this.$refs.modal.hide();
			})
		},
	},
};
</script>

<style>

</style>

