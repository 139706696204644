var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"check-repaired-batch","title":_vm.title,"ok-title":"Save","cancel-disabled":_vm.disableConfirmButtons,"ok-disabled":_vm.disableConfirmButtons,"no-close-on-backdrop":true},on:{"ok":_vm.handleOk}},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-form-group',{attrs:{"label":"Total:","label-cols-sm":"2","label-class":"font-weight-bold pt-0"}},[_vm._v(" "+_vm._s(_vm.form.repaired)+" ")]),_c('b-form-group',{attrs:{"label":"Good Assets:","label-for":"Good Assets"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
					required: true,
					regex: /^[0-9]*$/,

				}),expression:"{\n\t\t\t\t\trequired: true,\n\t\t\t\t\tregex: /^[0-9]*$/,\n\n\t\t\t\t}"}],attrs:{"name":"Good Asset","type":"number","maxlength":"50"},model:{value:(_vm.goodAsset),callback:function ($$v) {_vm.goodAsset=$$v},expression:"goodAsset"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Good Assets')),expression:"errors.has('Good Assets')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Good Assets'))+" ")])],1),_c('b-form-group',{attrs:{"label":"Rejected Assets:","label-for":"Rejected Assets"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
					required: true,
					regex: /^[0-9]*$/,

				}),expression:"{\n\t\t\t\t\trequired: true,\n\t\t\t\t\tregex: /^[0-9]*$/,\n\n\t\t\t\t}"}],attrs:{"name":"Rejected Asset","type":"number","maxlength":"50"},model:{value:(_vm.rejectedAsset),callback:function ($$v) {_vm.rejectedAsset=$$v},expression:"rejectedAsset"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Rejected Assets')),expression:"errors.has('Rejected Assets')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Rejected Assets'))+" ")])],1),_c('b-form-group',{attrs:{"label":"Checked By:","label-for":"Checked By"}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('selectRequired'),expression:"'selectRequired'"}],staticClass:"style-chooser",attrs:{"name":"Checked By","label":"text","options":_vm.allUserOptions,"reduce":(user) => user.value},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No results found for "),_c('em',[_c('strong',[_vm._v(_vm._s(search))])])]:_c('em',{style:({ opacity: 0.5 })},[_vm._v(" Start typing to search for a user ")])]}}]),model:{value:(_vm.selUser),callback:function ($$v) {_vm.selUser=$$v},expression:"selUser"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Checked By')),expression:"errors.has('Checked By')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Checked By'))+" ")])],1),_c('b-form-group',{attrs:{"label":"Remarks:","label-for":"Remarks","description":""}},[_c('b-form-textarea',{directives:[{name:"validate",rawName:"v-validate",value:({
					required: true,
					regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
				}),expression:"{\n\t\t\t\t\trequired: true,\n\t\t\t\t\tregex: /^([A-Za-z0-9\\s:;,.!'\\/()-_]{1,200})$/,\n\t\t\t\t}"}],attrs:{"name":"Remarks","type":"text","maxlength":"200","rows":3,"placeholder":"Remarks"},model:{value:(_vm.form.checkedRemarks),callback:function ($$v) {_vm.$set(_vm.form, "checkedRemarks", $$v)},expression:"form.checkedRemarks"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Remarks')),expression:"errors.has('Remarks')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Remarks'))+" ")])],1),_c('b-form-group',{attrs:{"label":"Turn Over Form:","label-for":"client-form","description":"*32-bit PNG, 1000px by 1000px, up to 1 MB"}},[_c('b-form-file',{ref:"turnover-form-file-input",attrs:{"id":"turnover-form","placeholder":"Choose image","accept":"image/png"},on:{"change":function($event){return _vm.onSelectTurnOverForm($event)}},scopedSlots:_vm._u([{key:"file-name",fn:function({ names }){return [_c('b-badge',{attrs:{"variant":"dark"}},[_vm._v(_vm._s(names[0]))]),(names.length > 1)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"dark"}},[_vm._v("+ "+_vm._s(names.length - 1)+" More files")]):_vm._e()]}}])})],1),(_vm.form._turnOverForm.url)?_c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"overlay":"","img-src":_vm.form._turnOverForm.url,"img-alt":"Card Image","text-variant":"white"}},[_c('b-card-text',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.onRemoveTurnOverForm()}}},[_c('em',{staticClass:"fa fa-trash"})])],1)],1)],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }